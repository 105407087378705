export default {
    adverbs: {
        by: "par",
        for_more_than_days: "depuis plus de {count}} jours",
        since: "depuis",
        on: "le",
        as_of: "en date du",
    },
    nouns: {
        account: "compte",
        address: "adresse",
        address_line_1: "Adresse",
        and: "et",
        another: "un autre",
        apt: "Appartement",
        apt_suite_unit: "Appartement/Suite/Unité",
        article: "article",
        at_the_company: "à la compagnie",
        basic_information: "Informations de base",
        city: "ville",
        comments: "commentaires",
        company: "entreprise",
        company_plural: "entreprises",
        confirm_password: "confirmer le mot de passe",
        invitation_status: "invitation",
        contact: "contact",
        copyright: "© 2020 Valital",
        country: "pays",
        date_of_birth: "date de naissance",
        day: "jour",
        department: "département",
        email: "courriel",
        employment: "emploi",
        end_date: "date de fin",
        first_name: "Prénom",
        help_and_support: "Aide et Support",
        info: "information",
        key_information: "information importante",
        last_name: "Nom de famille",
        last_validation: "dernière validation",
        legal: "légal",
        less_likely: "moins probable",
        link: "lien",
        month: "mois",
        most_likely: "plus probable",
        my_account: "mon compte",
        name: "nom",
        new: "nouveau",
        news: "nouvelles",
        next: "suivant",
        new_password: "nouveau mot de passe",
        no: "non",
        notifications: "notifications",
        ok: "ok",
        old_password: "ancien mot de passe",
        optional: 'Optionnel',
        password: "mot de passe",
        pending_consent: "invitation en suspens",
        pending_consent_plural: " invitations en suspens ",
        period_worked: " période travaillée",
        personal_email: "Courriel Personnel",
        personal_info: "informations personnelles",
        phone: "téléphone",
        phone_number: "numéro de téléphone ",
        platform: "platforme",
        postal_code: "code postal ",
        privacy_email: "privacy@valital.com",
        privacy_statement: "Politique de confidentialité",
        profile: "profil",
        profile_completion: "finalisation du profil",
        province: "province",
        province_state: "Province/État",
        reason: "Que peut-on ameliorer?",
        reference_1: "une référence",
        reference: "référence",
        references: "références",
        reminder: "rappel",
        reset_password: "réinitialiser le mot de passe",
        role: "rôle",
        security_settings: "Paramètres de sécurité",
        settings: "Paramètres",
        social_links: "liens sociaux",
        social_links_1: "des liens sociaux",
        start_date: "date de début",
        switch_language: "english",
        terms_of_use: "Conditions d'utilisation",
        validation_status: "statut de la validation",
        work_email: "Courriel professionnel",
        year: "année",
        yes: "oui",
        your: "votre",
    },
    verbs: {
        add: "ajouter",
        added: "ajouté",
        cancel: "annuler",
        decline: "refuser",
        denied: "refusé",
        delete: "effacer",
        edit: "modifier",
        finish: "terminer",
        go_back: "fermer",
        loading: "chargement en cours",
        login: "se connecter",
        logout: "se déconnecter",
        pending: "en suspens",
        remind: "renvoyer",
        remove: "supprimer",
        revoke: "révoquer",
        none_selected: "Aucune sélectionnée",
        count_selected: "{{count}} sélectionnée",
        send: "envoyer",
        sent: "envoyé",
        submit: "soumettre",
        update: "mettre à jour",
        validated: "validé",
    }
};
