import * as React from 'react';

/**
 * Hook that knows if you click outside of its given reference.
 */
const useOutsideClick = () => {
    const ref = React.useRef(null);
    const [didClickOutside, setDidClickOutside] = React.useState(false);

    const handleClick = React.useCallback((event) => {
        setDidClickOutside(ref != null && ref.current != null && !ref.current.contains(event.target));
    }, [ref]);

    React.useEffect(() => {
        document.addEventListener('mousedown', handleClick);
        return () => document.removeEventListener('mousedown', handleClick);
    }, [handleClick]);
    
    return [ref, didClickOutside];
};

export default useOutsideClick;
