// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import moment from 'moment';
import { withRouter } from 'react-router-dom';
import type { RouterHistory } from 'react-router-dom';
import { PROFILE, LOGIN } from '../../../utils/routes';
import { useSetToken } from '../../../utils/app-context';
import ImagePlaceHolder from '../../../components/image-placeholder';
import Dropdown from '../../../components/dropdown';
import useOutsideClick from '../../../hooks/useOutsideClick';
import styles from './styles.module.scss';

type Props = {
    history: RouterHistory,
    picture: ?string,
};

type DropdownOption = 'PROFILE' | 'TERMS' | 'PRIVACY' | 'LOGOUT' | 'LANGUAGE';

const dropdownOptionsFactory = (t: i18next.TFunction) => [
    { value: 'PROFILE', label: `${t('verbs.edit')} ${t('nouns.profile')}` },
    { value: 'LANGUAGE', label: `${t('nouns.switch_language')}` },
    { value: 'LOGOUT', label: t('verbs.logout') },
];

const ProfilePictureWithDropdown = ({ history, picture }: Props) => {
    const { t } = useTranslation();
    const setToken = useSetToken();
    const [containerRef, didClickOutside] = useOutsideClick();
    const [isDropdownVisible, setDropdownVisible] = React.useState(false);

    const DROPDOWN_OPTIONS = React.useMemo(() => dropdownOptionsFactory(t), [t]);

    React.useEffect(() => {
        if (isDropdownVisible && didClickOutside) {
            setDropdownVisible(false);
        }
    }, [isDropdownVisible, didClickOutside, setDropdownVisible]);

    /** Actions */

    const onEditProfile = () => {
        history.push(PROFILE);
        setDropdownVisible(false);
    };

    const onSwitchLanguage = () => {
        if (i18next.language != null && i18next.language.includes('fr')) {
            i18next.changeLanguage('en');
            moment.locale('en');
        } else {
            i18next.changeLanguage('fr');
            moment.locale('fr');
        }
    };

    const onLogout = () => {
        setToken(null);
        history.push(LOGIN);
        setDropdownVisible(false);
    };

    const handleClick = (value: DropdownOption) => {
        switch (value) {
            case 'PROFILE':
                return onEditProfile();
            case 'LANGUAGE':
                return onSwitchLanguage();
            case 'LOGOUT':
                return onLogout();
            default:
                return;
        }
    };

    return (
        <div ref={containerRef} className={styles.profile_picture_holder}>
            <div
                className={styles.image_wrapper}
                onClick={() => setDropdownVisible(!isDropdownVisible)}
            >
                <ImagePlaceHolder src={picture} />
            </div>
            {isDropdownVisible && (
                <div className={styles.profile_dropdown_container}>
                    <Dropdown 
                        withArrow={true}
                        options={DROPDOWN_OPTIONS} 
                        onClick={handleClick} 
                    />
                </div>
            )}
        </div>
    );
};

export default withRouter(ProfilePictureWithDropdown);
