// @flow
import * as React from 'react';

/**
 * Circle Cross svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
        >
            <g fill="none" fillRule="evenodd">
                <circle cx="7.5" cy="7.5" r="6.5" stroke="#D0021B" strokeWidth="2" />
                <rect
                    width="1"
                    height="7"
                    x="7"
                    y="4"
                    fill="#D0021B"
                    fillRule="nonzero"
                    rx=".5"
                    transform="rotate(45 7.5 7.5)"
                />
                <rect
                    width="1"
                    height="7"
                    x="7"
                    y="4"
                    fill="#D0021B"
                    fillRule="nonzero"
                    rx=".5"
                    transform="rotate(-45 7.5 7.5)"
                />
            </g>
        </svg>
    );
};
