// @flow
import * as React from 'react';
import styles from './styles.module.scss';

export type Option = {
    value: any,
    label: string,
};

type Props = {
    options: Array<any>,
    onClick: (value: any) => void,
    renderItem?: (option: any) => React.Element<any>,
    className?: string,
    withArrow?: boolean,
};

const Dropdown = ({ options, onClick, renderItem, className = '', withArrow }: Props) => (
    <div className={`${styles.dropdown_container} ${className}`}>
        {options.map((option, i) =>
            renderItem == null ? (
                <div
                    key={option.value}
                    className={styles.dropdown_row}
                    onClick={() => onClick(option.value)}
                >
                    {(withArrow && i === 0) && (
                        <div className={styles.arrow_up}>
                            <div />
                        </div>
                    )}
                    <span className={styles.dropdown_text}>{option.label}</span>
                </div>
            ) : (
                renderItem(option)
            ),
        )}
    </div>
);

export default Dropdown;

// Export other types of Dropdown, too
export { default as MultiChoiceDropdown } from './multi-choice';
