// @flow
import * as React from 'react';

/**
 * Cross svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="15" viewBox="0 0 14 15">
            <path d="M7 6.086L12.303.782l1.415 1.415L8.414 7.5l5.304 5.303-1.415 1.415L7 8.914l-5.303 5.304-1.415-1.415L5.586 7.5.282 2.197 1.697.782 7 6.086z" />
        </svg>
    );
};
