// @flow
import * as React from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import queryString from "query-string";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import moment from "moment";
import Intercom from "react-intercom";

import "App.scss";
import ThemeProvider from "providers/ThemeProvider.tsx";
import NavBar from "containers/navbar";
import {
  LOGIN,
  VALIDATIONS,
  PROFILE,
  PUBLIC_INFORMATION,
  REFERRALS,
  ACTIVATE,
  CONFIRM_REFERENCE,
  FORWARD_REFERENCE,
  DELETE_FEEDBACK,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  TERMS,
  PRIVACY,
} from "utils/routes";
import { useToken, useUser, useRefreshToken } from "utils/app-context";

const BottomBanner = React.lazy(() => import("./components/bottom-banner"));
const Validations = React.lazy(() => import("./containers/validations"));
const PublicInformation = React.lazy(() =>
  import("./containers/public-information"),
);
const Referrals = React.lazy(() => import("./containers/referrals"));
const Login = React.lazy(() => import("./containers/login"));
const Activate = React.lazy(() => import("./containers/activate"));
const Profile = React.lazy(() => import("./containers/profile"));
const ConfirmReferral = React.lazy(() =>
  import("./containers/contacts/ConfirmContact"),
);
const ForwardReference = React.lazy(() =>
  import("./containers/contacts/ForwardReference"),
);
const DeleteFeedback = React.lazy(() => import("./containers/delete-feedback"));
const ResetPassword = React.lazy(() => import("./containers/reset-password"));
const UpdatePassword = React.lazy(() => import("./containers/update-password"));
const Terms = React.lazy(() => import("./containers/terms"));
const Privacy = React.lazy(() => import("./containers/privacy"));

const switchLanguage = (language: string) => {
  if (language && i18next.language && !i18next.language.includes(language)) {
    i18next.changeLanguage(language);
    moment.locale(language);
  }
};

/**
 * The main interactive section (you have to be logged)
 *
 * @constructor
 */
const HomeSection = ({ location }) => {
  const params = queryString.parse(location.search);
  switchLanguage(params.language);

  return (
    <>
      {/* Top level navigation */}
      <React.Suspense fallback={<React.Fragment />}>
        <NavBar />
      </React.Suspense>

      {/* This is the menu for the mobile navigation */}
      <React.Suspense fallback={<React.Fragment />}>
        {/* <MobileMenuDrawer user={dummyUser} /> */}
      </React.Suspense>

      <div className="app-content" id="app-content">
        {/* Content navigation */}
        <React.Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route
              exact
              path={VALIDATIONS}
              component={(props) => <Validations {...props} />}
            />
            <Route
              exact
              path={REFERRALS}
              component={(props) => <Referrals {...props} />}
            />
            <Route
              path={PROFILE}
              component={(props) => <Profile {...props} />}
            />
            <Redirect to={VALIDATIONS} />
          </Switch>
        </React.Suspense>

        {/* Bottom banner with logo, terms, policy */}
        <React.Suspense fallback={<React.Fragment />}>
          <BottomBanner />
        </React.Suspense>
      </div>
    </>
  );
};

/**
 * The public section (Login sign up and all those things)
 *
 * @constructor
 */
const AuthSection = ({ location }) => {
  const token = useToken();
  const refreshToken = useRefreshToken();
  const params = queryString.parse(location.search);
  switchLanguage(params.language);

  return (
    <React.Suspense fallback={<div>Loading...</div>}>
      <Switch>
        <Route
          path={CONFIRM_REFERENCE}
          component={(props) => <ConfirmReferral {...props} />}
        />
        <Route
          path={FORWARD_REFERENCE}
          component={(props) => <ForwardReference {...props} />}
        />
        <Route
          path={DELETE_FEEDBACK}
          component={(props) => <DeleteFeedback {...props} />}
        />
        <Route path={TERMS} component={(props) => <Terms {...props} />} />
        <Route path={PRIVACY} component={(props) => <Privacy {...props} />} />
        {!(token && refreshToken) ? (
          <>
            <Route path={LOGIN} component={(props) => <Login {...props} />} />
            <Route
              path={ACTIVATE}
              component={(props) => <Activate {...props} />}
            />
            <Route
              path={RESET_PASSWORD}
              component={(props) => <ResetPassword {...props} />}
            />
            <Route
              path={UPDATE_PASSWORD}
              component={(props) => <UpdatePassword {...props} />}
            />
          </>
        ) : (
            <Redirect to={VALIDATIONS} />
          )}
      </Switch>
    </React.Suspense>
  );
};

const HOME_ROUTES = [VALIDATIONS, PUBLIC_INFORMATION, REFERRALS, PROFILE];
const AUTH_ROUTES = [
  LOGIN,
  ACTIVATE,
  CONFIRM_REFERENCE,
  FORWARD_REFERENCE,
  DELETE_FEEDBACK,
  RESET_PASSWORD,
  UPDATE_PASSWORD,
  TERMS,
  PRIVACY,
];

const HomeApp = () => {
  const token = useToken();
  const refreshToken = useRefreshToken();

  return (token && refreshToken) ? (
    <Route
      path={HOME_ROUTES}
      component={(props) => <HomeSection {...props} />}
    />
  ) : (
      <Redirect to={LOGIN} />
    );
};

const IntercomApp = () => {
  const user = useUser();
  const { i18n } = useTranslation();

  return user && i18n ? (
    <Intercom
      appID={process.env.REACT_APP_INTERCOM_KEY}
      {...{
        user_id: user.id,
        email: user.email,
        name: `${user.first_name} ${user.last_name}`,
        user_type: "talent",
        app_language: i18n.language,
        app_environment: process.env.NODE_ENV,
      }}
    />
  ) : null;
};

const App = () => {
  return (
    <ThemeProvider>
      <div className="app">
        <BrowserRouter>
          <Switch>
            <Route
              exact
              path={AUTH_ROUTES}
              component={(props) => <AuthSection {...props} />}
            />
            <HomeApp />
          </Switch>
        </BrowserRouter>
        <IntercomApp />
      </div>
    </ThemeProvider>
  );
};

export default App;
