// @flow
import * as React from 'react';

/**
 * Pending Dots svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="4" viewBox="0 0 18 4">
            <g fill="#F5A623" fillRule="nonzero">
                <circle cx="2" cy="2" r="2" />
                <circle cx="9" cy="2" r="2" />
                <circle cx="16" cy="2" r="2" />
            </g>
        </svg>
    );
};
