// @flow
import * as React from 'react';

/**
 * Checkmark svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" viewBox="0 0 19 14">
            <path d="M6.218 10.803L17.02 0l1.414 1.414-12.02 12.021-.197-.197-.197.197L0 7.415 1.414 6l4.804 4.803z" />
        </svg>
    );
};
