export default {
    errors: {
        invalid: "invalide",
        password_length: "le mot de passe doit être de plus de 8 charactère",
        no_lowercase: "doit inclure au moins une lettre minuscule",
        no_uppercase: "doit inclure au moins une lettre majuscule",
        no_number: "doit inclure au moins un numéro",
        invalid_date: 'date invalide',
        must_be_16_years: "Vous devez avoir au moins 16 ans",
        must_be_80_years: "La date est trop loin",
        not_internal_email: "Pas un courriel interne",
        no_article: "Choisissez un article",
        no_details: "S'il vous plaît donner les détails d'utilisation",
        not_found: "non trouvé",
        old_password_mismatch:
            "l'ancien mot de passe ne correspond pas au mot de passe actuel",
        passwords_mismatch: "les mots de passe ne  \n correspondent pas\n",
        required: "obligatoire",
        reset_token_expired: "réinitialiser jeton expiré",
        server_error: "Erreur du serveur",
        server_unreachable: "serveur inaccessible",
        too_long: "trop long",
        too_short: "doit avoir au moins {{count}} caractères",
        unauthorized: "non autorisé",
        activation_failed: "Il y a un problème avec le courriel d'activation que vous avez utilisé. Veuillez nous <1>contacter</1>. Code d'erreur: {{code}}",
        referrence_already_exist: "Vous avez déjà une référence avec ce courriel",
    },

    warnings: {
        not_an_email: "Ce n'est pas supposé être un email",
        personal_email: "Un courriel personnel est entré",
    },
};
