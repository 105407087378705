// @flow
import * as React from 'react';

/**
 * Circle Checkmark svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="14"
            viewBox="0 0 15 14"
        >
            <g fill="#7DCF95" fillRule="evenodd">
                <path d="M5.7 5.3c-.4-.4-1-.4-1.4 0-.4.4-.4 1 0 1.4l2 2c.2.2.4.3.7.3.3 0 .5-.1.7-.3l7-7c.4-.4.4-1 0-1.4-.4-.4-1-.4-1.4 0L7 6.6 5.7 5.3z" />
                <path d="M7 14c3.9 0 7-3.1 7-7 0-.6-.4-1-1-1s-1 .4-1 1c0 2.8-2.2 5-5 5S2 9.8 2 7s2.2-5 5-5c.6 0 1.1.1 1.7.3.5.2 1.1-.1 1.3-.6.2-.5-.1-1.1-.6-1.3C8.6.1 7.8 0 7 0 3.1 0 0 3.1 0 7s3.1 7 7 7z" />
            </g>
        </svg>
    );
};
