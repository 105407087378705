// @flow
import * as React from 'react';
import styles from './styles.module.scss';

type Props = {
    selected: boolean,
    toggling?: boolean,
    alternate?: boolean,
    checkbox?: boolean,
    onClick?: () => void,
};

const RadioButton = ({ selected, toggling, alternate, checkbox, onClick }: Props) => {
    const shape = checkbox ? 'square' : 'circle';
    return (
        <div className={styles[`outer_${shape}`]} onClick={onClick != null ? onClick : () => {}}>
            {(selected || toggling) && (
                !alternate ? (
                    <span
                    data-testid="radio-button-selected"
                    className={toggling ? styles[`inner_${shape}_toggling`] : styles[`inner_${shape}`]}
                    />
                    ) : (
                        <div
                        data-testid="radio-button-selected"
                        className={toggling ? styles[`inner_${shape}_alt_toggling`] : styles[`inner_${shape}_alt`]}
                        />
                        )
                        )}
        </div>
    );
};

export default RadioButton;
