import React from 'react';
import * as Sentry from '@sentry/browser';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';

class ErrorBoundary extends React.Component<
  {},
  { hasError: boolean; eventId: string | undefined }
> {

  constructor(props: {}) {
    super(props);
    this.state = { hasError: false, eventId: undefined };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: any) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      //render fallback UI
      return (
        <Box
          bgcolor="primary.main"
          display="flex"
          width="100vw"
          height="100vh"
          justifyContent="center"
          alignItems="center"
        >
          <Card>
            <CardContent>
              <Typography>Oops, something went wrong!</Typography>
              <Typography>
                We've been notified, but you can send more information below.
              </Typography>
            </CardContent>
            <CardActions>
              <Button
                color="primary"
                onClick={() =>
                  Sentry.showReportDialog({ eventId: this.state.eventId })
                }
              >
                Send feedback
              </Button>
            </CardActions>
          </Card>
        </Box>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;