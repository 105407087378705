export default {
    acknowledgement: "I acknowledge and agree to Valital's",
    back_to_home: "Back to Home",
    mark_all_as_read: "Mark All as Read",
    on_date: "on {{date}}",
    thank_you_for_your_input: "Thank you for your input!",

    notifications: {
        a_referral: "a referral",
        creator_added_subject: "{{creator_name}} added {{subject_name}}",
        creator_changed_status:
            "{{creator_name}} changed {{subject_name}}'s status",
        from_to: 'from "{{previous}}" to "{{next}}"',
        has_been_validated: "has been validated",
        has_been_declined: "has been declined",
        in_subject_behaviour: "in {{subject_name}}'s behaviour",
        on_date: " on {{date}}.",
        one_of: "One of",
        significant_change_noted: "significant change noted",
        subject_added: "{{subject_name}} added",
        subject_changed_status: "{{subject_name}} changed their status",
        subject_deleted: "{{subject_name}} deleted",
        subject_referrals: "{{subject_name}}'s referrals",
        subject_removed: "{{subject_name}} removed",
        subject_updated: "{{subject_name}} updated",
        their_account: "their account",
        to_the_company: "to the company's list of {{user_type}}s",
        refused: "Refused",
        accepted: "Accepted",
        pending: "Pending",
    },
    pages: {
        activate: {
            could_not_find_address: "Could not find the address you're looking for",
            password_format: '8 characters, 1 uppercase, 1 lowercase, 1 number',
            not_shared: 'Not shared with {{company}}',
            talent: {
                title_1: "Let's get you set up.",
                title_2: "Contact information",
                title_3: "Employment / Education",
                title_4: "References",
                currently_work_there: "Are you currently working at",
                employer: "Current or last employer",
                education: "Current or last academic institution",
                subtitle:
                    "Your company needs you now. Onboard faster than ever.",
                note: "We will never contact the organizations you mention above",
                skip: "Skip and add later >",
            },
        },
        contacts: {
          internal_email: "Must be an internal email (@{{internalDomain}})",
          success_page: {
            title: "Success!",
            note: "Wondering what Valital can do for you? <2>Check out our website</2>",
            button: "Try Valital For Free"
          },
          confirm_reference: {
            title: "One last step!",
            description: "Please confirm your information below to finalize your answer.",
            button: "Confirm Details",
            success: "Thank you for confirming {{name}}’s work experience. You’ve helped accelerate a company’s recruitment process."
          },
          email_confirmation: {
            title: "An email confirmation has been sent to you to verify your email address",
            description: "Please check your inbox for <span>{{email}}</span> to confirm your email address."
          },
          forward_reference: {
            title: "Forward the request internally",
            description: "Specify the person that will confirm {{name}}’s work experience",
            button: "Forward Reference",
            success: "Your request has been forwarded to <span>{{email}}</span>"
          },
        },
        delete_feedback: {
            title: "Until we meet again!",
            subtitle:
                "Your feedback matters. Please tell us why you deleted your account.",
        },
        login: {
            title: "Welcome to Valital",
            forgot_password: "Forgot your password?",
        },
        profile: {
            leave_blank:
                "Leave this blank if you don't plan on changing the password",
            password_changed: "Password changed successfully",
            delete: {
                talent: {
                    title: "Sorry to see you go, {{name}}.",
                    subtitle: "Before you delete your account, know this:",
                    body1:
                        "When deleting your account, companies to whom you have given your consent will be notified.",
                    body2:
                        "All your information will be deleted and you will have to revalidate your referrals if a new account is created.",
                    agreed:
                        "I have read and understood what it means to delete my account",
                }
            }
        },
        public_information: {
            name: "News about me",
            title: "News about you from previous validations",
            feedback: "Your feedback",
            loading: "The report is still loading. You will be notified once ready.",
            nothing: "No news to display",
            confirm: {
                title: "Thank you",
                body:
                    "Your comments have been sent to a human curator. After assessment, the proper actions will be taken and your informations may be updated accordingly.",
                footer: "Back to your profile",
            },
        },
        referrals: {
            name: "References",
            title_0: "YOU HAVE NO REFERENCES YET",
            title: "YOU HAVE {{count}} REFERENCE",
            title_plural: "YOU HAVE {{count}} REFERENCES",
            subtitle: "Click on the button below to add one.",
            empty_card_first: "Your first reference will appear here",
            empty_card: "Add a reference",
            remove: {
                title_1: "Remove",
                title_2: "from your references?",
                body:
                    "Removing this reference will notify the following companies:",
            },
            score_1: "Reference's email is not a Company domain",
            score_2: "{{company}} not found in Canadian federal databases",
            score_3: "Reference's email may not belong to {{company}}",
            add_new_reference: "Add a Reference",
            edit_a_reference: "Edit a Reference",
            about_them: "About your reference",
            about_you: "Your work at the company",
            extra_info: `A reference is a person at the company you mention below that can confirm that you have worked at that company. Your reference <b>must be</b> someone that is currently working there.
            <br/><br/>
            Valital will then send an email to your reference asking them to confirm <b>only</b> the information you have provided in this form.
            <br/><br/>
            <span>This confirmation is done only once and can be used by you as a proof of work for any future employer!</span>`,
            last_role: "Last role",
            which_companies: "Companies with access to this reference (Optional)",
        },
        reset_password: {
            title: "Forgot your password?",
            title_done: "Thank you!",
            body: "Enter your email to reset your password",
            body_done:
                "The password reset form has been sent. Please check your inbox for the next steps.",
        },
        search_results: {
            title: "All results for {{search}}",
            your_talents: "Your talents",
            not_your_company: "Not part of your company",
        },
        update_password: {
            body: "Please enter a new password for your account",
        },
        validations: {
            name: "Invitations",
            requests: "These companies want to work with you",
            no_requests: "No current validation request",
            cannot_change_consent:
                "You cannot change your consent until this company reactivates you",
            profile_completion_tips: "Add another referral to get to 90%",
            accept: {
                title_1: "You're almost there!",
                body_1:
                    "Valital will report to ",
                body_1_2:
                    " on public online information about me and/or on confirmation of my employment history from my designated references. The actual hiring decision is made by ",
                body_1_3:
                    " and Valital is not liable for the results of the report and its interpretation.",
                body_2:
                    "Want to change your invitation response later? You can do so on your dashboard at any time.",
                give_consent: "I agree ",
                questions: "Any questions? Contact us at",
            },
            decline: {
                title: "Decline {{name}}'s invitation request?",
                body_1:
                    "Declining the invitation request will notify {{name}} of your decision.",
                body_2:
                    "You may accept the invitation later, unless {{name}} cancels their request.",
            },
            referrals: {
                title: "Select the references you wish to send to {{name}}",
                subtitle: "Which references do you want to send to {{name}}?",
                subtitle_no_referral: "You currently have no references.",
                optimal_result: "For optimal results, we recommend including at least one reference"
            },
            revoke: {
                title: "Change your status?",
                body:
                    "Changing your status will notify {{name}} of your decision.",
            }
        }
    },
    reference_form: {
      company: "What company did you work in?",
      word_period: "How long did you work there?",
      from: "From",
      until: "Until",
      last_role: "What was your last role?",
      reference: "Add a former colleague that can confirm this information",
      allowed_companies: "Which companies can see this?",
      add_reference: "Add reference",
      edit_reference: "Edit reference"
    },
};
