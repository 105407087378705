import React, { useRef, useEffect } from 'react';
import styles from './styles.module.scss';
import _ from 'lodash';
import ImagePlaceholder from "../../components/image-placeholder";
import {Link} from "react-router-dom";
import { useTranslation } from 'react-i18next';
import i18next from "i18next";
import moment from 'moment';
import {
    VALIDATIONS,
    REFERRALS,
    PROFILE,
    PUBLIC_INFORMATION,
    LOGIN,
    TERMS,
    PRIVACY,
} from "../../utils/routes";
import {
    Camera,
    Profile,
    Flag,
    Dashboard,
    Consent,
    News,
    Referrals,
    EditProfile,
    Terms,
    Privacy,
    Send,
    Logout
} from "../../components/svg-icons";
import {useSetToken} from "../../utils/app-context";

const OPTIONS =   {
    duration: 600,
    fill: 'forwards',
    easing: 'ease-in-out'
};

const DRAWER_FRAMES = [
    {transform: 'translateX(0)'},
    {transform: 'translateX(-100%)'},
];

const OVERLAY_FRAMES = [
    {opacity: 0},
    {opacity: 0.5},
];


export const MobileDrawer = React.memo((props) => {

    const { isOpen, onCloseRequest, user } = props;

    const setToken = useSetToken();

    const drawerRef = useRef(null);
    const overlayRef = useRef(null);
    const coverRef = useRef(null);

    const drawerAnimation = useRef(null);
    const overlayAnimation = useRef(null);
    const { t } = useTranslation();

    const onSwitchLanguage = () => {
        if (i18next.language != null && i18next.language.includes('fr')) {
            i18next.changeLanguage('en');
            moment.locale('en');
        } else {
            i18next.changeLanguage('fr');
            moment.locale('fr');
        }
    };

    /**
     * React to open state change
     */
    useEffect(() => {

        if(isOpen) {
            coverRef.current.style.display = 'block';
        }

        if(drawerAnimation.current && drawerAnimation.current.playState === 'paused') {
            drawerAnimation.current.play();
        } else if(drawerAnimation.current) {
            drawerAnimation.current.reverse();
        }

        if(overlayAnimation.current && overlayAnimation.current.playState === 'paused') {
            overlayAnimation.current.play();
        } else if(overlayAnimation.current) {
            overlayAnimation.current.reverse();
        }


    }, [isOpen]);


    /**
     * Initialize the animations
     */
    useEffect(() => {

        // Define the drawer animation
        drawerAnimation.current = drawerRef.current.animate(DRAWER_FRAMES, OPTIONS);
        drawerAnimation.current.pause();

        // Define the overlay animation
        overlayAnimation.current = overlayRef.current.animate(OVERLAY_FRAMES, OPTIONS);
        overlayAnimation.current.pause();

    }, []);

    /**
     * Remove the page cover when the drawer is closed
     */
    if(overlayAnimation.current) {

        overlayAnimation.current.onfinish = function() {
            if(!isOpen) {
                coverRef.current.style.display = 'none';
            }
        };
    }

    return (
        <div className={styles.page_cover} ref={coverRef}>

            <div className={styles.overlay} ref={overlayRef} onClick={onCloseRequest}/>

            <div className={`${styles.drawer_content} ${isOpen ? styles.is_open : ''}`}
                 ref={drawerRef}
                 onClick={e => e.stopPropagation()}
            >
                <div className={styles.mobile_menu_scroll_area}>

                    {/* Profile line */}
                    {user &&
                        <div className={styles.profile_row}>
                                    <span className={styles.profile_picture_holder}>
                                        <ImagePlaceholder src={user.picture}/>
                                    </span>
                            <p>{user.first_name} {user.last_name}</p>
                        </div>
                    }

                    {/* Consent */}
                    <Link to={VALIDATIONS} style={{ textDecoration: 'none' }}>
                        <div className={styles.consent_row} onClick={onCloseRequest}>
                            <div className={styles.row_icon}>
                                <Consent />
                            </div>
                            <p className="normal_text">{t('sentences:pages.validations.name')}</p>
                        </div>
                    </Link>

                    {/* Referrals */}
                    <Link to={REFERRALS} style={{ textDecoration: 'none' }}>
                        <div className={styles.referrals_row} onClick={onCloseRequest}>
                            <div className={styles.row_icon}>
                                <Referrals />
                            </div>
                            <p className="normal_text">{t('sentences:pages.referrals.name')}</p>
                        </div>
                    </Link>

                    {/* Profile */}
                    <Link to={PROFILE} style={{ textDecoration: 'none' }}>
                        <div className={styles.edit_profile_row} onClick={onCloseRequest}>
                            <div className={styles.row_icon}>
                                <EditProfile />
                            </div>
                            <p className="normal_text">{_.upperFirst(t('nouns.profile'))}</p>
                        </div>
                    </Link>

                    <Link to={TERMS} style={{ textDecoration: 'none' }}>
                        <div className={styles.terms_row} onClick={onCloseRequest}>
                            <div className={styles.row_icon}>
                                <Terms />
                            </div>
                            <p className="normal_text">{_.upperFirst(t('nouns.terms_of_use'))}</p>
                        </div>
                    </Link>

                    <Link to={PRIVACY} style={{ textDecoration: 'none' }}>
                        <div className={styles.privacy_row} onClick={onCloseRequest}>
                            <div className={styles.row_icon}>
                                <Privacy />
                            </div>
                            <p className="normal_text">{t('nouns.privacy_statement')}</p>
                        </div>
                    </Link>

                    <div className={styles.language_row} onClick={onSwitchLanguage}>
                        <div className={styles.row_icon}>
                            <Send />
                        </div>
                        <p className="normal_text">{_.upperFirst(t('nouns.switch_language'))}</p>
                    </div>
                    
                    {/* Logout */}
                    <Link to={LOGIN} style={{ textDecoration: 'none' }}>
                        <div className={styles.log_out_row}
                             onClick={() => {
                                onCloseRequest();
                                setToken(null);
                             }}>
                            <div className={styles.row_icon}>
                                <Logout />
                            </div>
                            <p className="normal_text">{_.upperFirst(t('verbs.logout'))}</p>
                        </div>
                    </Link>


                </div>
            </div>

        </div>
    );

});