// @flow
import * as React from 'react';
import _ from 'lodash';
import io from 'socket.io-client';
import styles from './styles.module.scss';

import { Bell } from '../../../components/svg-icons';
import { useUser, useToken } from '../../../utils/app-context';
import useAPI from '../../../hooks/useAPI';

import NotificationList from './notification-list';

type Props = {};

const NotificationsBell = (props: Props) => {
    const API = useAPI();
    const user = useUser();
    const token = useToken();
    const [hasNotifications, setHasNotifications] = React.useState(false);
    const [notificationsVisible, setNotificationsVisible] = React.useState(false);

    const socket = io(process.env.REACT_APP_SERVER_URL);

    // Always keep the current socket.io connection aware of the token state
    React.useEffect(() => {
        socket.emit('token_update', token);
        socket.on('notification', () => {
            setHasNotifications(true);
        });
    }, [token, socket]);

    // Always keep track of the current `has_notifications`
    React.useEffect(() => {
        if (user != null) {
            setHasNotifications(_.get(user, ['employments', '0', 'has_notifications']));
        }
    }, [user, setHasNotifications]);

    const removeBadge = React.useCallback(() => {
        if (hasNotifications) {
            const id = _.get(user, ['employments', '0', 'id']);
            API.patch(`talents/${id}`, { has_notifications: false }).then(() =>
                setHasNotifications(false),
            );
        }
    }, [API, user, hasNotifications, setHasNotifications]);

    const handleClick = React.useCallback(() => {
        removeBadge();
        if (!notificationsVisible) {
            setNotificationsVisible(true);
        }
    }, [removeBadge, notificationsVisible, setNotificationsVisible]);

    return (
        <div className={styles.bell_holder} onClick={handleClick}>
            <Bell />
            {hasNotifications && (
                <div className={styles.badge}>
                    <span />
                </div>
            )}
            {notificationsVisible && <NotificationList onDismiss={() => setNotificationsVisible(false)} />}
        </div>
    );
};

export default NotificationsBell;
