export default {
    adverbs: {
        by: "by",
        for_more_than_days: "for more than {{count}} days",
        since: "since",
        on: "on",
        as_of: "as of",
    },
    nouns: {
        account: "account",
        address: "address",
        address_line_1: "address line 1",
        and: "and",
        another: "another",
        apt: "Apt.",
        apt_suite_unit: "Apt/Suite/Unit",
        article: "article",
        at_the_company: "at the company",
        basic_information: "Basic information",
        city: "city",
        comments: "comments",
        company: "company",
        company_plural: "companies",
        confirm_password: "confirm password",
        invitation_status: "invitation status",
        contact: "contact",
        copyright: "© 2020 Valital",
        country: "country",
        date_of_birth: "date of birth",
        day: "day",
        department: "department",
        email: "email",
        employment: "employment",
        end_date: "end date",
        first_name: "First name",
        help_and_support: "Help & Support",
        info: "info",
        key_information: "basic information",
        last_name: "Last name",
        last_validation: "last validation",
        legal: "legal",
        less_likely: "less likely",
        link: "link",
        month: "month",
        most_likely: "most likely",
        my_account: "my account",
        name: "name",
        new: "new",
        news: "news",
        next: "next",
        new_password: "new password",
        no: "no",
        notifications: "notifications",
        ok: "ok",
        old_password: "old password",
        optional: 'Optional',
        password: "password",
        pending_consent: "pending invitation",
        pending_consent_plural: "pending invitations",
        period_worked: "work period",
        personal_email: "Personal Email",
        personal_info: "personal information",
        phone: "phone",
        phone_number: "primary phone number",
        platform: "platform",
        postal_code: "postal code",
        privacy_email: "privacy@valital.com",
        privacy_statement: "Privacy Statement",
        profile: "profile",
        profile_completion: "profile completion",
        province: "province",
        province_state: "Province/State",
        reason: "What can we do better?",
        reference_1: "a reference",
        reference: "reference",
        references: "references",
        reminder: "reminder",
        reset_password: "reset password",
        role: "role",
        security_settings: "Security settings",
        settings: "settings",
        social_links: "social links",
        social_links_1: "a social links",
        start_date: "start date",
        switch_language: "français",
        terms_of_use: "Terms of Use",
        validation_status: "validation status",
        work_email: "Work email",
        year: "year",
        yes: "yes",
        your: "your",
    },
    verbs: {
        add: "add",
        added: "added",
        cancel: "cancel",
        decline: "decline",
        denied: "Denied",
        delete: "delete",
        edit: "edit",
        finish: "finish",
        go_back: "go back",
        loading: "loading",
        login: "log in",
        logout: "log out",
        pending: "pending",
        remind: "remind",
        remove: "remove",
        revoke: "revoke",
        none_selected: "None selected",
        count_selected: "{{count}} selected",
        send: "send",
        sent: "sent",
        submit: "submit",
        update: "update",
        validated: "validated",
    }
};
