// @flow
import * as React from 'react';

/**
 * Bell svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="22" height="24" viewBox="0 0 22 24">
            <g fill="none" fillRule="evenodd">
            <path stroke="#000" strokeWidth="2" d="M3 20h16V10a7 7 0 0 0-7-7h-2a7 7 0 0 0-7 7v10z"/>
            <rect width="22" height="2" y="19" fill="#000" fillRule="nonzero" rx="1"/>
            <path fill="#000" fillRule="nonzero" d="M9 22h5v2H9zM11 0a2 2 0 0 1 2 2H9a2 2 0 0 1 2-2z"/>
            </g>
        </svg>
    );

}