export default {
    acknowledgement: "Je reconnais et accepte la politique de Valital",
    back_to_home: "retour à la page principale",
    mark_all_as_read: "Cocher le tout comme lu",
    on_date: "en date du {{date}}",
    thank_you_for_your_input: "Merci pour votre contribution!",

    notifications: {
        a_referral: "une référence",
        creator_added_subject: "{{creator_name}} a ajouté {{subject_name}}",
        creator_changed_status:
            "{{creator_name}} a changé le statut de {{subject_name}}",
        from_to: 'de "{{previous}}" à "{{next}}"',
        has_been_validated: "a été validée",
        has_been_declined: "a été refusé",
        in_subject_behaviour: "dans les habitudes de {{subject_name}}",
        on_date: " en date du {{date}}.",
        one_of: "L'une des",
        significant_change_noted: "un changemment important a été noté",
        subject_added: "{{subject_name}} a ajouté",
        subject_changed_status: "{{subject_name}} a changé le statut",
        subject_deleted: "{{subject_name}} a effacé",
        subject_referrals: "références de {{subject_name}}",
        subject_removed: "{{subject_name}} a supprimé",
        subject_updated: "{{subject_name}} mis à jour",
        their_account: "son compte",
        to_the_company: "à la liste des utilisateurs de l'entreprise de type {{user_type}}",
        refused: "Refusé",
        accepted: "Accepté",
        pending: "En Suspens",
    },
    pages: {
        activate: {
            could_not_find_address: "On a pas trouvé trouver l'adresse que vous recherchez",
            password_format: '8 caractères, 1 majuscule, 1 minuscule, 1 numéro',
            not_shared: 'Ne sera pas partagé avec {{company}}',
            talent: {
                title_1: "Création de votre compte",
                title_2: "Coordonnées",
                title_3: "Emploi / Éducation",
                title_4: "Références",
                currently_work_there: "Êtes-vous actuellement à l'emploi chez",
                employer: "Employeur actuel ou récent",
                education: "Établissement d'enseignement actuel ou récent",
                subtitle:
                    "Rejoignez Valital pour une relation de confiance avec votre employeur.",
                note: "Nous ne contacterons jamais les organisations que vous mentionnez ci-dessus",
                skip: "Ignorer et ajouter plus tard >",
            }
        },
        contacts: {
          internal_email: "Doit être un courriel interne (@{{internalDomain}})",
          success_page: {
            title: "Vous avez réussi!",
            note: "Vous vous demandez ce que Valital peut faire pour vous? <2>Consultez notre site Web</2>",
            button: "Essayez Valital gratuitement"
          },
          confirm_reference: {
            title: "Une dernière étape!",
            description: "Veuillez confirmer vos informations ci-dessous pour finaliser votre réponse.",
            button: "Confirmer Les Détails",
            success: "Merci d'avoir confirmé l'expérience de travail de {{name}}. Vous avez contribué à accélérer le processus de recrutement d'une entreprise."
          },
          email_confirmation: {
            title: "Un courriel de confirmation vous a été envoyé pour vérifier votre adresse courriel",
            description: "Veuillez vérifier votre boîte de réception <span>{{email}}</span> pour confirmer votre adresse courriel."
          },
          forward_reference: {
            title: "Transférer la demande à l’interne",
            description: "Précisez le nom de la personne qui confirmera l'expérience d’emploi de {{name}}",
            button: "Transférer La Référence",
            success: "Votre message a été envoyé à <span>{{email}}</span>"
          },
        },
        delete_feedback: {
            title: "À la prochaine!",
            subtitle:
                "Votre opinion nous importe. Dites-nous la raison pour laquelle vous avez supprimé votre compte",
        },
        login: {
            title: "Bienvenue à Valital",
            forgot_password: "Avez-vous oublié votre mot de passe?",
        },
        profile: {
            leave_blank:
                "Laissez vide si vous ne désirez pas modifier votre mot de passe",
            password_changed: "Le mot de passe a été modifié avec succès",
            delete: {
                talent: {
                    title: "désolé de vous voir partir, {{name}}.",
                    subtitle: "Avant d'annuler votre compte il est important de savoir:",
                    body1:
                        "Lorsque vous supprimez votre compte, les entreprises auxquelles vous avez donné votre invitation seront notifiées",
                    body2:
                        "Toutes vos informations seront perdues et vous devez revalider vos références en cas de création d'un nouveau compte.",
                    agreed:
                        "J'ai lu et compris ce que signifie d'annuler mon compte",
                }
            }
        },
        public_information: {
            name: "Nouvelles à mon propos",
            title: "Nouvelles à votre propos de la dernière validation",
            loading: "Le rapport est en traitement. Vous serez avisés quand le rapport sera prêt.",
            nothing: "Pas de nouvelles à afficher",
            feedback: "Vos commentaires",
            confirm: {
                title: "Merci",
                body:
                    "Vos commentaires ont été envoyés à un curateur humain. Après évaluation, les actions appropriées seront prises et vos informations pourront être mises à jour en conséquence.",
                footer: "Retour à votre profil",
            },
        },
        referrals: {
            name: "Références",
            title_0: "vous n'avez pas encore de références",
            title: "Vous avez {{count}} référence",
            title_plural: "Vous avez {{count}} références",
            subtitle: "Cliquez sur le bouton ci-dessous pour en ajouter une",
            empty_card_first: "Votre première référence apparaîtra ici",
            empty_card: "Ajouter une référence",
            remove: {
                title_1: "Supprimer",
                title_2: "de vos références?",
                body:
                    "Si vous supprimez cette référence, les entreprises suivantes en seront notifiées:",
            },
            score_1: "Le courriel de référence n'a pas un domaine de compagnie",
            score_2: "{{company}} est introuvable dans la base de données d’entreprises fédérales canadiennes",
            score_3: "Le courriel de référence pourrait ne pas appartenir à {{company}}",
            add_new_reference: "Ajouter une référence",
            edit_a_reference: "Modifier une référence",
            about_them: "À propos de votre référence",
            about_you: "Votre role a l’entreprise",
            extra_info: `Une référence est une personne de la compagnie mentionnée ci-dessous qui peut confirmer que vous avez travaillé dans cette société. Votre référence <b>doit être</b> une personne qui est actuellement à l’emploi dans cette compagnie.
            <br/><br/>
            Un courriel sera envoyé à votre référence pour lui demander de confirmer <b>uniquement</b> les informations que vous avez fournies dans ce formulaire.
            <br/><br/>
            <span>Cette confirmation est faite une seule fois et peut être utilisée par vous comme preuve de travail pour tous vos employeurs futurs!</span>`,
            last_role: "Dernier rôle",
            which_companies: "Entreprises qui auront accès a votre reference (Optionnel)",
        },
        reset_password: {
            title: "Mot de passe oublié?",
            title_done: "Merci!",
            body: "Veuillez entrer votre adresse courriel pour réinitialiser votre mot de passe",
            body_done:
                "Le formulaire de réinitialisation du mot de passe a été envoyé. Veuillez vérifier votre boîte de réception pour les prochaines étapes",
        },
        search_results: {
            title: "Tous les résultats pour {{search}}",
            your_talents: "Vos talents",
            not_your_company: "Ne font pas partie de votre entreprise",
        },
        update_password: {
            body: "Veuillez saisir un nouveau mot de passe pour votre compte",
        },
        validations: {
            name: "Invitations",
            requests: "Ces entreprises veulent travailler avec vous",
            no_requests: "Aucune demande de validation en cours",
            cannot_change_consent:
                "Vous ne pouvez pas changer votre consentement avant que cette entreprise ne vous ait désactivé",
            profile_completion_tips: "Ajoutez une autre référence pour obtenir 90%",
            accept: {
                title_1: "Vous y êtes presque!",
                body_1:
                    " Valital fournira un rapport à ",
                body_1_2:
                    " sur les informations publiques à mon sujet, et/ou sur la confirmation de mon historique d’emploi à partir des références que j’ai données. La décision d'embauche est prise par ",
                body_1_3:
                    ". Valital n'est pas responsable des résultats du rapport et de son interprétation.",
                body_2:
                    "Vous souhaitez modifier votre réponse à l'invitation plus tard? Vous pouvez le faire à tout moment à partir de votre tableau de bord.",
                give_consent: "J'accepte",
                questions: "Avez-vous des questions?",
            },
            decline: {
                title: "Refusez-vous le invitation de {{name}}?",
                body_1:
                    "En refusant l'invitation vous notifier {{name}} de votre décision.",
                body_2:
                    "Vous pouvez accepter le invitation ultérieurement, à moins que {{name}} n'annule sa demande.",
            },
            referrals: {
                title: "Choisissez les références à envoyer à {{name}}",
                subtitle: "Lesquels voulez-vous envoyer?",
                subtitle_no_referral: "Vous n'avez actuellement aucune référence.",
                optimal_result: "Pour des résultats optimaux, nous vous recommandons d’inclure au moins une référence."
            },
            revoke: {
                title: "Modifiez votre statut?",
                body:
                    "En modifiant votre statut vous informez {{name}} de votre décision.",
            }
        }
    },
    reference_form: {
      company: "Où avez-vous travaillé?",
      word_period: "Combien de temps avez-vous travaillé chez cette compagnie?",
      from: "De",
      until: "Jusqu'à",
      last_role: "Quel était votre dernier rôle?",
      reference: "Ajoutez un(e) ancien collègue qui peut confirmer cette information",
      allowed_companies: "Quelles compagnies auront accès à cette information?",
      add_reference: "Ajoutez la référence",
      edit_reference: "Modifiez la référence"
    },
};
