// @flow
import * as React from 'react';
import styles from './styles.module.scss';

type Props = {
    animationProgress?: number
}

export default (props: Props) => {

    const styleOne = props.animationProgress != null ? {
        transform: `rotate(${ props.animationProgress * 45 }deg)` ,
        transformOrigin: '0 center'
    } : undefined;

    const styleTwo = props.animationProgress != null ? {
        opacity: 1 - props.animationProgress * 2
    } : undefined;


    const styleThree = props.animationProgress != null ? {
        transform: `rotate(${ props.animationProgress * -45 }deg)` ,
        transformOrigin: '0 center'
    } : undefined;

    return (
        <div className={styles.hamburger}>
            <div className={styles.line_one} style={styleOne} />
            <div className={styles.line_two} style={styleTwo} />
            <div className={styles.line_three} style={styleThree} />
        </div>
    );

}

