// @flow
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styles from './styles.module.scss';

import logo from '../../assets/images/logo-valital.jpg';
import PageSelector from '../../components/page-selector';
import { Bell } from '../../components/svg-icons';
import ProfilePictureWithDropdown from './profile-picture-with-dropdown';
import Hamburger from './hamburger';
import NotificationsBell from './notifications-bell';
import { VALIDATIONS, PUBLIC_INFORMATION, REFERRALS } from '../../utils/routes';
import { useUser } from '../../utils/app-context';
import { MobileDrawer } from "../mobile-drawer";

type Props = {};

/**
 * Component representing the sticky navbar
 */
const NavBar = (props: Props) => {
    const user = useUser();
    const { t } = useTranslation();
    const [mobileDrawerOpen, setMobileDrawerOpen] = React.useState<boolean>(false);
    
    return (
        <div>

            {/* Desktop content */}
            <div className={styles.desktop_content}>
                <Link to={VALIDATIONS}>
                    <img className={styles.logo} src={logo} alt="logo" />
                </Link>

                <PageSelector
                    pages={[
                        { name: t('sentences:pages.validations.name'), route: VALIDATIONS },
                        { name: t('sentences:pages.referrals.name'), route: REFERRALS },
                    ]}
                />

                <div className={styles.right_section}>
                    {/* <NotificationsBell /> */}

                    <ProfilePictureWithDropdown picture={user ? user.picture : null} />
                </div>
            </div>

            {/* Mobile content */}
            <MobileDrawer
                isOpen={mobileDrawerOpen}
                user={user}
                onCloseRequest={() => setMobileDrawerOpen(false)}
            />

            <div className={styles.mobile_content}>

                <React.Fragment>
                    <Link to={VALIDATIONS}>
                        <div className={styles.mobile_logo_holder}>
                            <img className={styles.logo} src={logo} alt="logo" />
                        </div>
                    </Link>

                    <div className={styles.mobile_right_content}>
                        {/* <div className={styles.mobile_bell_holder}>
                            <Bell />
                        </div> */}

                        <div
                            className={styles.mobile_hamburger_holder}
                            onClick={() => setMobileDrawerOpen(true)}
                        >
                            <Hamburger
                                /*animationProgress={rawRatio}*/
                            />
                        </div>
                    </div>
                </React.Fragment>
            </div>
        </div>
    );
};

export default NavBar;
