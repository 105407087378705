// @flow
import * as React from 'react';

/**
 * Refresh svg
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17">
            <path d="M8.5 14.875A6.366 6.366 0 0 1 2.125 8.5 6.366 6.366 0 0 1 8.5 2.125c1.757 0 3.343.737 4.49 1.884L9.918 7.083H17V0l-2.493 2.493C12.963.963 10.852 0 8.5 0 3.81 0 .014 3.81.014 8.5c0 4.69 3.797 8.5 8.486 8.5a8.47 8.47 0 0 0 8.358-7.083h-2.153c-.652 2.833-3.173 4.958-6.205 4.958z" />
        </svg>
    );
};
