// @flow
import * as React from 'react';
import * as LocalStorage from './local-storage';
import _ from 'lodash';
import axios from 'axios';


const AppContext = React.createContext({
    token: '', setToken: (t) => { },
    refreshToken: '', setRefreshToken: (t) => { },
    user: null, setUser: (u) => { },
});
const { useState, useEffect, useContext } = React;


/**
 * Custom hook to get the token setter
 */
export const useSetToken = () => {

    const { setToken } = useContext(AppContext);

    return setToken;
};

/**
 * Custom hook to get the current token
 */
export const useToken = () => {

    const { token } = useContext(AppContext);

    return token;
};

export const useSetRefreshToken = () => {

    const { setRefreshToken } = useContext(AppContext);

    return setRefreshToken;
};

export const useRefreshToken = () => {

    const { refreshToken } = useContext(AppContext);

    return refreshToken;
};

/**
 * Custom hook to get the user setter
 */
export const useSetUser = () => {

    const { setUser } = useContext(AppContext);

    return setUser;
};

/**
 * Custom hook to get the current user
 */
export const useUser = () => {

    const { user } = useContext(AppContext);

    return user;
}


export const AppProvider = (props) => {

    const { initialToken, initialRefreshToken } = props;
    const [token, setToken] = useState(initialToken);
    const [refreshToken, setRefreshToken] = useState(initialRefreshToken);
    const [user, setUser] = useState(null);

    const updateUser = React.useCallback(() => {
        if (token != null) {
            axios.get('/auth/me', { headers: { 'Authorization': `Bearer ${token}` } })
                .then(({ data }) => {
                    const employments = data.employments.filter(x => x.user_type === 'TALENT');
                    setUser({ ...data, employments, company: _.get(employments, ['0', 'company'], {}) });
                })
                .catch(err => {
                    if (_.get(err, ['response', 'status'])) {
                        setUser(null);
                        setRefreshToken(null);
                        setToken(null);
                    }
                })
        }
    }, [token, refreshToken, setToken, setRefreshToken, setUser]);

    useEffect(() => {
        LocalStorage.setToken(token);
        LocalStorage.setRefreshToken(refreshToken);
        if (!token || !refreshToken) {
            setUser(null);
        } else {
            updateUser();
        }
    }, [token, refreshToken, setUser]);

    return (
        <AppContext.Provider value={{ token, setToken, refreshToken, setRefreshToken, user, setUser }}>
            {props.children}
        </AppContext.Provider>
    );

};

