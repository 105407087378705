export default {
    errors: {
        invalid: "Invalid",
        password_length: "Password should be more than 8 characters",
        no_lowercase: "Should include at least one lowercase letter",
        no_uppercase: "Should include at least one uppercase letter",
        no_number: "Should include at least one number",
        invalid_date: "Invalid date",
        must_be_16_years: "You must be at least 16 years old",
        must_be_80_years: "The date is too far away",
        not_internal_email: "Not an internal email",
        no_article: "Please choose an article",
        no_details: "Please give use details",
        not_found: "Not found",
        old_password_mismatch:
            "The old password does not match the current password",
        passwords_mismatch: "Passwords do not match",
        required: "Required",
        reset_token_expired: "Reset token expired",
        server_error: "Server error",
        server_unreachable: "Server unreachable",
        too_long: "Too long",
        too_short: "Must be at least {{count}} characters long",
        unauthorized: "Unauthorized",
        activation_failed: "There is a problem with the activation email you used. Please contact our <1>support</1>. Error code: {{code}}",
        referrence_already_exist: "You already added a reference with this email",
    },

    warnings: {
        not_an_email: "Not supposed to be an email",
        personal_email: "Personal email entered",
    },
};
