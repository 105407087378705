// @flow

// Routing
export const VALIDATIONS = '/';
export const PUBLIC_INFORMATION = '/public-information';
export const REFERRALS = '/references';
export const LOGIN = '/login';
export const ACTIVATE = '/activate';
export const PROFILE = '/profile';
export const CONFIRM_REFERENCE = '/confirm-reference/:referenceId';
export const DELETE_FEEDBACK = '/delete-feedback';
export const RESET_PASSWORD = '/reset-password';
export const UPDATE_PASSWORD = '/update-password';
export const TERMS = '/terms';
export const PRIVACY = '/privacy';
export const FORWARD_REFERENCE = '/forward-reference/:referenceId';

