import 'web-animations-js';

import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { AppProvider } from "./utils/app-context";
import * as LocalStorage from './utils/local-storage';
import './utils/i18n';
import ErrorBoundary from './error-boundary';

const {
  REACT_APP_SERVER_URL = '',
  REACT_APP_SENTRY_DSN = '',
  REACT_APP_SENTRY_ENV = 'dev',
  REACT_APP_SENTRY_RELEASE = 'dev',
} = process.env;

// Axios
const baseURL: string = `${REACT_APP_SERVER_URL}/v1`;
axios.defaults.baseURL = baseURL;

// Sentry
Sentry.init({
  dsn: REACT_APP_SENTRY_DSN,
  environment: REACT_APP_SENTRY_ENV,
  release: REACT_APP_SENTRY_RELEASE,
});

// Token
const initialToken = LocalStorage.getToken();
const initialRefreshToken = LocalStorage.getRefreshToken();

ReactDOM.render(
    <ErrorBoundary>
        <AppProvider initialToken={initialToken} initialRefreshToken={initialRefreshToken}>
            <App />
        </AppProvider>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
