// @flow
import * as React from 'react';
import { Link, withRouter } from 'react-router-dom';
import type { Location } from 'react-router-dom';
import styles from './styles.module.scss';

type Props = {
    pages: Array<{
        name: string,
        route: string,
    }>,
    location: Location,
};

const getLinkStyle = (page, selected) => {
    if (page.route === selected) {
        return `normal_text ${styles.nav_link} ${styles.selected_link}`;
    } else {
        return `normal_text ${styles.nav_link}`;
    }
};

const getHolderStyle = (page, selected) => {
    if (page.route === selected) {
        return `${styles.link_holder} ${styles.underlined}`;
    } else {
        return styles.link_holder;
    }
};

const PageSelector = ({ pages, location }: Props) => {
    return (
        <div className={styles.container}>
            {pages.map(page => (
                <div className={getHolderStyle(page, location.pathname)} key={page.name}>
                    <Link to={page.route}>
                        <span className={getLinkStyle(page, location.pathname)}>{page.name}</span>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default withRouter(PageSelector);
