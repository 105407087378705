// @flow
import * as React from 'react';
import _ from 'lodash';
import styles from './styles.module.scss';

import RadioButton from '../radio-button';

import Dropdown from './index';
import type { Option } from './index';

type Props = {
    alternate?: boolean,
    options: Array<Option>,
    selected: Array<Option>,
    toggling: ?number,
    onClick: (value: any) => void,
};

const isSelected = (option, selected) =>
    _.some(selected, ({ value }) => value === option.value);

const isToggling = (option, toggling) => toggling === option.value;

const MultiChoiceDropdown = ({ alternate, options, selected, toggling, onClick }: Props) => (
    <Dropdown
        options={options}
        onClick={_.noop}
        className={styles.multi_choice_container}
        renderItem={(option: Option) => (
            <div
                key={option.value}
                onClick={() => onClick(option.value)}
                className={styles.multi_choice_row}
            >
                <RadioButton
                    alternate={alternate}
                    selected={isSelected(option, selected)}
                    toggling={isToggling(option, toggling)}
                />
                <span>{option.label}</span>
            </div>
        )}
    />
);

export default MultiChoiceDropdown;
