// @flow
import * as React from 'react';

/**
 * Flag
 *
 * @param props
 * @return {XML}
 */
export default (props: {}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <path d="M12 8.031l4-6.031h-16v-2h-2v24h2v-10h16l-4-5.969zm-12-4.031h12.273l-2.677 4.037 2.656 3.963h-12.252v-8z" />
        </svg>
    );
};
